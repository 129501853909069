<template>
  <div>
    <div v-if="magicLinkSent">
      <BaseCard class="text-center">
        <h1 class="text-3xl">
          Check your inbox
        </h1>
        <Mailbox class="w-56 mx-auto my-6 text-center"/>
        <p class="text-lg">
          We’ve emailed a link to <span class="text-xl font-semibold">{{ formResponses.email }}</span>
        </p>
      </BaseCard>
    </div>
    <div
      v-else
      class="p-6 bg-white shadow-lg"
    >
      <h1 class="text-2xl md:text-3xl xl:text-4xl">
        Sign in <router-link
          :to="{ name: 'sign-up' }"
          class="block text-base"
        >
          or <span class="font-medium cta-link">create an account</span>
        </router-link>
      </h1>

      <div class="mt-8 mb-4">
        <SignInWithGoogle
          @success="signInWithGoogleSuccess"
          @error="signInWithGoogleError"
        />
        <p
          v-if="signInWithGoogleErrorMessage"
          class="mt-2 text-xs text-left text-red-500"
        >
          {{ signInWithGoogleErrorMessage }}
        </p>
      </div>

      <form
        class="mt-8"
        novalidate
        @submit.prevent
      >
        <label class="relative block mt-6">
          <span class="text-gray-800">Email address</span>
          <input
            v-model="$v.formResponses.email.$model"
            type="email"
            class="block w-full mt-1 bg-gray-100 form-input"
            placeholder="Email address"
          >
        </label>
        <ErrorsInline v-if="errorMessage">
          {{ errorMessage }}
        </ErrorsInline>

        <section>
          <BaseButton
            class="mt-8"
            :loading="isProcessing"
            :disabled="!canSubmitEmail"
            @click="submit"
          >
            Send magic link
          </BaseButton>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SignInWithGoogle from '@components/Auth/SignInWithGoogle'
import ErrorsInline from '@components/ErrorsInline'
import Mailbox from '@components/Vectors/Mailbox'
import { getLocalStorage } from '@utils/localStorage'

import employersApi from '@api/employers'

import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
    SignInWithGoogle,
    ErrorsInline,
    Mailbox
  },

  mixins: [validationMixin],

  data() {
    return {
      isProcessing: false,
      errorMessage: null,
      signInWithGoogleErrorMessage: null,
      magicLinkSent: false,
      formResponses: {
        email: null
      }
    }
  },

  computed: {
    /**
     * @return {Boolean}
     */
    canSubmitEmail() {
      return !this.$v.formResponses.$invalid
    },

    /**
     * @return {Boolean}
     */
    isSignedIn() {
      return !!getLocalStorage('employer.token')
    }
  },

  created() {
    if (this.isSignedIn) {
      this.$router.push({ name: 'client-assessments-list' })
    }
  },

  validations: {
    formResponses: {
      email: {
        required,
        email
      }
    }
  },

  methods: {
    submit() {
      this.isProcessing = true
      this.errorMessage = null

      employersApi.magicLink(this.formResponses.email)
        .then(response => {
          if (response.data.success) {
            this.magicLinkSent = true
            this.isProcessing = false

            if (this.$gtm.trackEvent) {
              this.$gtm.trackEvent({
                event: 'event',
                eventCategory: 'Employer',
                eventAction: 'Sign in',
                eventLabel: 'Via email'
              })
            }

            return
          }
          throw new Error('Response did not contain success')
        })
        .catch(error => {
          this.isProcessing = false
          if (error.response && error.response.data && error.response.data.errorMessage) {
            this.errorMessage = error.response.data.errorMessage
            return
          }
          throw error
        })
    },

    /**
     * Handle successful sign in
     *
     * @param {String}
     */
    signInWithGoogleSuccess(idToken) {
      this.isProcessing = true

      this.$store.dispatch('employers/signInViaGoogle', idToken)
        .then(() => {
          this.isProcessing = false
          this.errorStatus = null
          this.signInWithGoogleErrorMessage = null

          if (this.$gtm.trackEvent) {
            this.$gtm.trackEvent({
              event: 'event',
              eventCategory: 'Employer',
              eventAction: 'Sign in',
              eventLabel: 'Via Google'
            })
          }

          this.$router.push({ name: 'client-assessments-list' })
        })
        .catch((error) => {
          this.isProcessing = false
          if (!error.response) {
            this.signInWithGoogleErrorMessage = error.message
            return
          }
          if (error.response.data.pat_status) {
            this.errorStatus = error.response.data.pat_status
          } else if (error.response.data.message) {
            this.signInWithGoogleErrorMessage = error.response.data.message
          } else {
            this.signInWithGoogleErrorMessage = error.response.data.errorMessage
          }
        })
    },

    /**
     * Handle error from Google Sign In
     *
     * @param {Object}
     */
    signInWithGoogleError(error) {
      if (error.error === 'popup_closed_by_user') {
        return
      }
      this.signInWithGoogleErrorMessage = error.error
    }

  }
}
</script>
